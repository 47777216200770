import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { styled } from "@mui/material/styles"

// Custom styled DatePicker component for RFI whitelabel
export default styled(DatePicker)({
  "& .MuiInputBase-input": {
    color: "#ffffff",
    fontWeight: "300",
  },

  "& label": {
    color: "rgba(255, 255, 255, 0.5)",
    fontWeight: "300",
  },

  "& label.Mui-focused": {
    color: "#ffffff",
  },

  "& .MuiInput-underline:after": {
    borderBottomColor: "#938F99",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#938F99",
    },

    "&:hover fieldset": {
      borderColor: "#938F99",
    },
    
    "&.Mui-focused fieldset": {
      borderColor: "#938F99",
    },
  },

  "& .MuiSvgIcon-root": {
    color: "#938F99",
  },
})
