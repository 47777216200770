import FormControl from "@mui/material/FormControl"
import { styled } from "@mui/material/styles"

// Custom styled FormControl Checkbox and Radio component for RFI whitelabel
export default styled(FormControl)({
  "& .MuiFormLabel-root": {
    color: "#ffffff",
    fontWeight: "300",
    marginBottom: "1em",

    "&.Mui-focused": {
      color: "#ffffff",
    },
  },

  "& .MuiButtonBase-root": {
    color: "#eb5d2a",
    paddingLeft: "1.5em",
  },

  "& .MuiFormControlLabel-root": {
    color: "rgba(230, 224, 233, 0.8)",
    fontWeight: "300",
  },

  // Force all layers of MUI's tangled mess of styles to be one single color
  "& .Mui-checked": {
    color: "#eb5d2a",
  },

  "& .MuiButtonBase-root checked": {
    color: "#eb5d2a",
  },

  "& .PrivateSwitchBase-input checked": {
    color: "#eb5d2a",
  },

  "& .MuiSvgIcon-root": {
    color: "#eb5d2a",
  },
})
