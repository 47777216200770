import { Button } from "@mui/material"
import { styled } from "@mui/material/styles"

// Custom styled Button component for RFI whitelabel
export default styled(Button)({
  backgroundColor: "rgba(255, 255, 255, 0.7)",
  border: "none",
  borderRadius: "30px",
  color: "#000000",
  fontFamily: "\"Helvetica Neue\", sans-serif",
  fontSize: "1em",
  fontWeight: 700,
  height: "60px",
  width: "200px",

  // NOTE: Yeah... explicitly overwriting these styles is necessary
  // to prevent MUI's invasive styles from taking over...
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 1)",
    border: "none",
    color: "#000000",
  },
})
