import { useState } from "react"
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { DemoContainer } from "@mui/x-date-pickers/internals/demo"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import moment, { Moment } from "moment"

import RFIButton from "./component/RFIButton"
import RFICheckboxRadioFormControl from "./component/RFICheckboxRadioFormControl"
import RFIDatePicker from "./component/RFIDatePicker"
import RFISelectFormControl from "./component/RFISelectFormControl"
import RFITextField from "./component/RFITextField"
import { Maybe } from "./util/type"
import * as Misc from "./util/misc"
import * as Validation from "./util/validation"

import "./App.sass"

type Props = {}

const App = (props: Props): JSX.Element => {
  // STATE MANAGEMENT
  // Meta form state
  const [ formSubmitLoading, setFormSubmitLoading ] = useState<boolean>(false)
  const [ formSubmitResponse, setFormSubmitResponse ] = useState<Maybe<number>>()
  const [ formSubmitAttempted, setFormSubmitAttempted ] =
    useState<boolean>(false)

  // Seller details state
  const [ sellerName, setSellerName ] = useState<Maybe<string>>("")
  const [ sellerEmail, setSellerEmail ] = useState<Maybe<string>>("")
  const [ agencyDetails, setAgencyDetails ] = useState<Maybe<string>>("")
  const [ brand, setBrand ] = useState<Maybe<string>>("")

  // Opportunity details state
  const [ opportunityName, setOpportunityName ] = useState<Maybe<string>>("")
  const [ proposedBudget, setProposedBudget ] = useState<Maybe<string>>("")
  const [ startDate, setStartDate ] = useState<Moment>(moment())
  const [ endDate, setEndDate ] = useState<Moment>(moment().add(1, "days"))
  const [ kpis, setKpis ] = useState<string>("Engagement")
  const [ creativeNotes, setCreativeNotes ] = useState<Maybe<string>>("")

  const [ interestFacebook, setInterestFacebook ] = useState<boolean>(false)
  const [ interestInstagram, setInterestInstagram ] = useState<boolean>(false)
  const [ interestSnapchat, setInterestSnapchat ] = useState<boolean>(false)
  const [ interestTikTok, setInterestTikTok ] = useState<boolean>(false)
  const [ interestYouTube, setInterestYouTube ] = useState<boolean>(false)
  const [ paidMedia, setPaidMedia ] = useState<Maybe<string>>("Yes")
  const [ influencerNotes, setInfluencerNotes ] = useState<Maybe<string>>("")

  // Client details state
  const [ exclusivityTerms, setExclusivityTerms ] = useState<Maybe<string>>("Yes")
  const [ specificUsageTerms, setSpecificUsageTerms ] = useState<Maybe<string>>("Yes")
  const [ specificTargeting, setSpecificTargeting ] = useState<Maybe<string>>("")

  // LOADING VIEW LOGIC
  if (formSubmitLoading) {
    return (
      <div className="rfi_whitelabel-container">
        <header />
        <section>
          <aside className="rfi_external-logo" /> {/* External logo */}
          <aside className="rfi_internal-logo" /> {/* Internal logo */}
          <h1>Submit an RFP</h1>

          {/* Summary text section */}
          <div className="rfi_form-loading">
            <CircularProgress size={ 60 } color="inherit" />
          </div>
        </section>
      </div>
    )
  }

  // FORM SUBMITTED VIEW LOGIC
  if (formSubmitResponse && formSubmitResponse !== 200) {
    return (
      <div className="rfi_whitelabel-container">
        <header />
        <section>
          <aside className="rfi_external-logo" /> {/* External logo */}
          <aside className="rfi_internal-logo" /> {/* Internal logo */}
          <h1>Submit an RFP</h1>

          {/* Summary text section */}
          <div className="rfi_form-block">
            <h2>{ formSubmitResponse }</h2>
            <p>There was an issue submitting the RFP!</p>
          </div>
        </section>
      </div>
    )
  }

  if (formSubmitResponse && formSubmitResponse === 200) {
    return (
      <div className="rfi_whitelabel-container">
        <header />
        <section>
          <aside className="rfi_external-logo" /> {/* External logo */}
          <aside className="rfi_internal-logo" /> {/* Internal logo */}
          <h1>Submit an RFP</h1>

          {/* Summary text section */}
          <div className="rfi_form-block">
            <h2>RFP Successfully Submitted!</h2>
            <p>An Influential representative will be in touch shortly!</p>
          </div>
        </section>
      </div>
    )
  }

  // FORM VIEW LOGIC
  return (
    <div className="rfi_whitelabel-container">
      <header />
      <section>
        <aside className="rfi_external-logo" /> {/* External logo */}
        <aside className="rfi_internal-logo" /> {/* Internal logo */}
        <h1>Submit an RFP</h1>

        {/* Summary text section */}
        <div className="rfi_form-block">
          <h2>Audacy + Influential Campaign RFP Submission</h2>
          <p>
            Please complete this form in order to submit a RFP for a potential 
            Audacy x Influential campaign.
          </p>
        </div>

        {/* Seller details form section */}
        <div className="rfi_form-block">
          <h2>Seller Details</h2>
          <section>
            <aside>
              <RFITextField
                label="Seller Name"
                id="rfiinput_seller-name"
                required={ true }
                fullWidth={ true }
                inputProps={{ maxLength: 100 }}
                value={ sellerName }
                onChange={ (e) => setSellerName(e.target.value) }
                error={
                  formSubmitAttempted
                  && Validation.isEmpty(sellerName)
                }
              />
            </aside>
            <aside>
              <RFITextField
                label="Seller Email"
                id="rfiinput_seller-email"
                required={ true }
                fullWidth={ true }
                inputProps={{ maxLength: 100 }}
                value={ sellerEmail }
                onChange={ (e) => setSellerEmail(e.target.value) }
                error={
                  formSubmitAttempted
                  && !Validation.isValidEmail(sellerEmail)
                }
              />
            </aside>
          </section>
          <section>
            <aside>
              <RFITextField
                label="Agency Details"
                id="rfiinput_agency-details"
                fullWidth={ true }
                inputProps={{ maxLength: 100 }}
                value={ agencyDetails }
                onChange={ (e) => setAgencyDetails(e.target.value) }
              />
            </aside>
            <aside>
              <RFITextField
                label="Brand"
                id="rfiinput_brand"
                fullWidth={ true }
                inputProps={{ maxLength: 100 }}
                value={ brand }
                onChange={ (e) => setBrand(e.target.value) }
              />
            </aside>
          </section>
        </div>

        {/* Opportunity details form section */}
        <div className="rfi_form-block">
          <h2>Opportunity Details</h2>
          <section>
            <aside>
              <RFITextField
                label="Opportunity Name"
                id="rfiinput_opportunity-name"
                required={ true }
                fullWidth={ true }
                value={ opportunityName }
                onChange={ (e) => setOpportunityName(e.target.value) }
                error={
                  formSubmitAttempted
                  && Validation.isEmpty(opportunityName)
                }
              />
            </aside>
            <aside>
              <RFITextField
                label="Proposed Budget"
                id="rfiinput_proposed-budget"
                required={ true }
                fullWidth={ true }
                value={ proposedBudget }
                onChange={ (e) => setProposedBudget(e.target.value) }
                error={
                  formSubmitAttempted
                  && Validation.isEmpty(proposedBudget)
                }
              />
            </aside>
          </section>
          <section>
            <aside>
              <LocalizationProvider dateAdapter={ AdapterMoment }>
                <DemoContainer
                  components={ [ "RFIDatePicker"] }
                  sx={{ "& .MuiFormControl-root": { width: "100%" } }}
                >
                  <RFIDatePicker
                    label="Start Date"
                    disablePast={ true }
                    value={ startDate }
                    onChange={ async (s) => {
                      // NOTE: MUI's min and max date props don't always
                      // function as expected, so the extra checks are
                      // necessary.

                      // Convince TypeScript that `s` is of type `Moment`
                      if (!moment.isMoment(s)) return

                      // Start date may never be in the past
                      if (s.isBefore(moment())) {
                        await setStartDate(moment())
                      }

                      // End date may never be earlier than start date
                      if (endDate.isBefore(s)) {
                        setEndDate(s)
                      }

                      // Start date is valid. Set.
                      setStartDate(s)
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </aside>
            <aside>
              <LocalizationProvider dateAdapter={ AdapterMoment }>
                <DemoContainer
                  components={ [ "RFIDatePicker"] }
                  sx={{ "& .MuiFormControl-root": { width: "100%" } }}
                >
                  <RFIDatePicker
                    label="End Date"
                    disablePast={ true }
                    minDate={ startDate }
                    value={ endDate }
                    onChange={ async (s) => {
                      // NOTE: MUI's min and max date props don't always
                      // function as expected, so the extra checks are
                      // necessary.

                      // Convince TypeScript that `s` is of type `Moment`
                      if (!moment.isMoment(s)) return

                      // End date may never be in the past
                      if (s.isBefore(moment())) {
                        await setEndDate(moment())
                      }

                      // End date may never be earlier than start date
                      if (s.isBefore(startDate)) {
                        console.log("end date before start date")
                        setEndDate(startDate.add(1, "days"))
                        return
                      }

                      // End date is valid. Set.
                      setEndDate(s)
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </aside>
          </section>
          <section>
            <RFICheckboxRadioFormControl>
              <FormLabel id="rfiinput_kpi-label">
                Please provide KPIs this opportunity is associated with:
              </FormLabel>
              <RadioGroup
                aria-labelledby="rfiinput_kpi-label"
                name="rfiinput_kpi-group"
                defaultValue="Engagement"
                value={ kpis }
                onChange={ (e) => setKpis(e.target.value) }
              >
                <FormControlLabel
                  value="Engagement"
                  control={ <Radio /> }
                  label="Engagement"
                />
                <FormControlLabel
                  value="Awareness/Reach"
                  control={ <Radio /> }
                  label="Awareness/Reach"
                />
                <FormControlLabel
                  value="Clicks"
                  control={ <Radio /> }
                  label="Clicks"
                />
                <FormControlLabel
                  value="Conversions"
                  control={ <Radio /> }
                  label="Conversions"
                />
                <FormControlLabel
                  value="Other"
                  control={ <Radio /> }
                  label="Other"
                />
              </RadioGroup>
            </RFICheckboxRadioFormControl>
          </section>
          <section>
            <RFITextField
              label="Creative Notes"
              id="rfiinput_creative-notes"
              fullWidth={ true }
              multiline={ true }
              inputProps={{ maxLength: 500 }}
              rows={ 4 }
              value={ creativeNotes }
              onChange={ (e) => setCreativeNotes(e.target.value) }
            />
          </section>
          <section>
            <RFICheckboxRadioFormControl>
              <FormLabel id="rfiinput_platforms-of-interest-label">
                Platforms of Interest:
              </FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={ 
                    <Checkbox
                      checked={ interestFacebook }
                      onChange={ () => setInterestFacebook(!interestFacebook) }
                    />
                  }
                  label="Facebook"
                  name="rfiinput_platforms-of-interest-facebook"
                />
                <FormControlLabel
                  control={ 
                    <Checkbox
                      checked={ interestInstagram }
                      onChange={ () => setInterestInstagram(!interestInstagram) }
                    />
                  }
                  label="Instagram"
                  name="rfiinput_platforms-of-interest-instagram"
                />
                <FormControlLabel
                  control={ 
                    <Checkbox
                      checked={ interestSnapchat }
                      onChange={ () => setInterestSnapchat(!interestSnapchat) }
                    />
                  }
                  label="Snapchat"
                  name="rfiinput_platforms-of-interest-snapchat"
                />
                <FormControlLabel
                  control={ 
                    <Checkbox
                      checked={ interestTikTok }
                      onChange={ () => setInterestTikTok(!interestTikTok) }
                    />
                  }
                  label="TikTok"
                  name="rfiinput_platforms-of-interest-tiktok"
                />
                <FormControlLabel
                  control={ 
                    <Checkbox
                      checked={ interestYouTube }
                      onChange={ () => setInterestYouTube(!interestYouTube) }
                    />
                  }
                  label="YouTube"
                  name="rfiinput_platforms-of-interest-youtube"
                />

              </FormGroup>
            </RFICheckboxRadioFormControl>
          </section>
          <section>
            <RFICheckboxRadioFormControl>
              <FormLabel id="rfiinput_paid-media-label">
                Is the client interested in Paid Media:
              </FormLabel>
              <RadioGroup
                aria-labelledby="rfiinput_paid-media-label"
                name="rfiinput_paid-media-group"
                defaultValue="Yes"
                value={ paidMedia }
                onChange={ (e) => setPaidMedia(e.target.value) }
              >
                <FormControlLabel
                  value="Yes"
                  control={ <Radio /> }
                  label="Yes"
                />
                <FormControlLabel
                  value="No"
                  control={ <Radio /> }
                  label="No"
                />
                <FormControlLabel
                  value="Unsure"
                  control={ <Radio /> }
                  label="Unsure"
                />
              </RadioGroup>
            </RFICheckboxRadioFormControl>
          </section>
          <section>
            <RFITextField
              label="Influencer Notes"
              id="rfiinput_influencer-notes"
              fullWidth={ true }
              multiline={ true }
              inputProps={{ maxLength: 500 }}
              rows={ 4 }
              value={ influencerNotes }
              onChange={ (e) => setInfluencerNotes(e.target.value) }
            />
          </section>
        </div>

        {/* Client details form section */}
        <div className="rfi_form-block">
          <h2>Client Details</h2>
          <section>
            <aside>
              <RFISelectFormControl fullWidth>
                <InputLabel id="rfiinput_exclusivity-terms-label">
                  Exclusivity Terms
                </InputLabel>
                <Select
                  labelId="rfiinput_exclusivity-terms-label"
                  id="rfiinput_exclusivity-terms"
                  label="Exclusivity Terms"
                  value={ exclusivityTerms }
                  onChange={ (e) => setExclusivityTerms(e.target.value) }
                >
                  <MenuItem
                    defaultChecked={ true }
                    value={ "Yes" }
                  >
                    Yes
                  </MenuItem>
                  <MenuItem value={ "No" }>
                    No
                  </MenuItem>
                  <MenuItem value={ "Unsure" }>
                    Unsure
                  </MenuItem>
                </Select>
              </RFISelectFormControl>
            </aside>
            <aside>
              <RFISelectFormControl fullWidth>
                <InputLabel id="rfiinput_usage-terms-label">
                  Specific Usage Terms
                </InputLabel>
                <Select
                  labelId="rfiinput_usage-terms-label"
                  id="rfiinput_usage-terms"
                  label="Specific Usage Terms"
                  value={ specificUsageTerms }
                  onChange={ (e) => setSpecificUsageTerms(e.target.value) }
                >
                  <MenuItem
                    defaultChecked={ true }
                    value={ "Yes" }
                  >
                    Yes
                  </MenuItem>
                  <MenuItem value={ "No" }>
                    No
                  </MenuItem>
                  <MenuItem value={ "Unsure" }>
                    Unsure
                  </MenuItem>
                </Select>
              </RFISelectFormControl>
            </aside>
          </section>
          <RFITextField
            label="Does Client Require Specific Targeting?"
            id="rfiinput_specific-targeting"
            fullWidth={ true }
            multiline={ true }
            inputProps={{ maxLength: 500 }}
            rows={ 4 }
            value={ specificTargeting }
            onChange={ (e) => setSpecificTargeting(e.target.value) }
          />
        </div>

        {/* Submit button form section */}
        <div className="rfi_form-block">
          <section className="rfi_form-submit">
            <RFIButton
              variant="outlined"
              onClick={ async () => {
                setFormSubmitAttempted(true)

                // Check all required fields
                const requiredFormFielsAreValid = [
                  Validation.isValidEmail(sellerEmail),
                  Validation.isNotEmpty(sellerName),
                  Validation.isNotEmpty(opportunityName),
                  Validation.isNotEmpty(proposedBudget),
                ].every((valid) => valid)

                if (!requiredFormFielsAreValid) return

                setFormSubmitLoading(true)

                const platformsOfInterest = Misc.platformsOfInterestToString(
                  interestFacebook,
                  interestInstagram,
                  interestSnapchat,
                  interestTikTok,
                  interestYouTube,
                )

                const submitted = await Misc.postForm({
                  sellerName,
                  sellerEmail,
                  agencyDetails,
                  brand,
                  opportunityName,
                  proposedBudget,
                  startDate: startDate.format("MMMM Do, YYYY"),
                  endDate: endDate.format("MMMM Do, YYYY"),
                  kpis,
                  creativeNotes,
                  platformsOfInterest,
                  paidMedia,
                  influencerNotes,
                  exclusivityTerms,
                  specificUsageTerms,
                  specificTargeting,
                })

                setFormSubmitResponse(submitted)
                setFormSubmitLoading(false)
              }}
              disabled={ formSubmitLoading }
            >
              Submit
            </RFIButton>
          </section>
        </div>
      </section>
    </div>
  );
}

export default App
