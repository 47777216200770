const defaultConfig = {
  TIN: {
    APP: {
      email_service: "http://localhost:49025",
      recipientEmail: "dcortese@influential.co",
      recipientName: "David",
      whitelabelRfpEmailSlug: "/send/whitelabel-rfp",
    },
  },
}

export default defaultConfig
