import { Maybe } from "./type"

// Regex lifted from Primm
const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const isEmpty = (val: Maybe<string>): boolean => val == null
  || val === ""

export const isNotEmpty = (val: Maybe<string>): val is string => val != null
  && val !== ""

export const isValidEmail = (val: Maybe<string>): boolean => isNotEmpty(val)
  && EMAIL_REGEX.test(val)
