// TODO: At some point this import may be changed to use dynamic configs
import Config from "../config/defaults"
import { Maybe } from "./type"

export function platformsOfInterestToString(
  facebook: boolean,
  instagram: boolean,
  snapchat: boolean,
  tiktok: boolean,
  youtube: boolean,
): string {
  let platforms = []

  if (facebook) platforms.push("Facebook")
  if (instagram) platforms.push("Instagram")
  if (snapchat) platforms.push("Snapchat")
  if (tiktok) platforms.push("TikTok")
  if (youtube) platforms.push("YouTube")

  return platforms.join(", ")
}

export async function postForm(formData: {
  sellerName: Maybe<string>,
  sellerEmail: Maybe<string>,
  agencyDetails: Maybe<string>,
  brand: Maybe<string>,
  opportunityName: Maybe<string>,
  proposedBudget: Maybe<string>,
  startDate: Maybe<string>,
  endDate: Maybe<string>,
  kpis: Maybe<string>,
  creativeNotes: Maybe<string>,
  platformsOfInterest: Maybe<string>,
  paidMedia: Maybe<string>,
  influencerNotes: Maybe<string>,
  exclusivityTerms: Maybe<string>,
  specificUsageTerms: Maybe<string>,
  specificTargeting: Maybe<string>,
}): Promise<number> {
  const url = `${Config.TIN.APP.email_service}${Config.TIN.APP.whitelabelRfpEmailSlug}`
  const headers = {
    "Content-Type": "application/json",
  }

  const data = {
    recipient: Config.TIN.APP.recipientEmail,
    viewContext: {
      recipientName: Config.TIN.APP.recipientName,
      ...formData,
    },
  }

  try {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers,
    })

    return response.status
  } catch (err) {
    console.log("Error occurred sending email:", err)

    return 500
  }
}
